<template>
	<div class="authentiaciton" v-loading="loading">
		<div class="fotm-content" v-if="status != 1">
			<div class="form-box">
				<div class="content">
					<div class="box">
						<div class="list" v-if="stampIngList.length">
							<div class="image-list" v-for="(item,index) in stampIngList" :key="index">
								<div class="image-status" v-if="item.sealStatus == 'SUCCESS'">
									已启用
								</div>
								<div class="image-status status-no" v-else>
									已弃用
								</div>
								<div class="image-item">
									<img :src="item.url" alt="">
								</div>
								<div class="image-name">
									{{item.sealName}}
								</div>
							</div>
							<div style="visibility: hidden;width: 275px;height: 1px;margin-right: 25px;"></div>
						</div>
						<div class="empty-title" v-if="showrenStatus" @click="goauth">
							还未认证，<span class="click-btn">请先去企宗认证</span>
						</div>
						<div class="empty-title"  @click="addStamping"  v-else-if="stampIngList.length == 0">
							<span>暂无印章，</span>
							<span class="click-btn">添加电子印章</span>
						</div>
						<div class="empty-title"  @click="addStamping"  v-else>
							<div class="add-btn">
								<el-button
								@click="addStamping"
									type="primary"
									size="medium" :plain="true"
								>
									添加电子印章
								</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="fotm-content" v-else-if="status == 1">
			<div class="img-box">
				<img :src="$img('/upload/web/shenhezhong.png')" alt="">
				您的资料正在审核，请耐心等待
			</div>
		</div>
	</div>
</template>

<script>
	import { addressInfo, saveAddress ,refreshOrganization, getMemberOrganizationInfo,getSealList,addStamping} from "@/api/member/member"
	import { getArea } from "@/api/address"
	import Config from '@/utils/config';
	import {
		memberStatus,
		memberStatusInfo
	} from "@/api/auth/login"
	import {
		mapGetters
	} from "vuex"
	export default {
	    name: "address_edit",
	    components: {},
	    computed: {
	    	...mapGetters(["member"]),
	        pickedArr() {
	            // 进行初始化
	            if (this.isInitMultiArray) {
	                return [this.pickerValueArray[0], this.pickerValueArray[1], this.pickerValueArray[2]]
	            }
	            return [this.pickerValueArray[0], this.city, this.district]
	        }
	    },
		data() {
	        let self = this
	        var isoperator_mobile = (rule, value, callback) => {
	            if (!value) {
	                return callback(new Error("手机号不能为空"))
	            } else {
	                const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/
	
	                if (reg.test(value)) {
	                    callback()
	                } else {
	                    callback(new Error("请输入正确的手机号"))
	                }
	            }
	        }
	
	        var fullAddress = (rule, value, callback) => {
	            if (self.formData.province_id) {
	                if (self.formData.city_id) {
	                    if (self.formData.district_id) {
	                        return callback()
	                    } else {
	                        return callback(new Error("请选择区/县"))
	                    }
	                } else {
	                    return callback(new Error("请选择市"))
	                }
	            } else {
	                return callback(new Error("请选择省"))
	            }
	        }
	
	        return {
				tabIndex:0,
				status:0,
				idCardZ:[],
				idCardF:[],
				business_license:[],
	            formData: {
	               organization_name: '', //公司名称
	               province_id:'', // 省id
	               city_id: '', //市id
	               district_id: '', // 区id
	               province_name: '', // 省名称
	               city_name: '', // 市名称
	               district_name:'', // 区名称
	               full_address:'',
	               operator_mobile: '', //手机
				   operator_name:'',
	               business_license: '', //  营业执照
				   shop_province_name: '', // 省名称
				   shop_city_name: '', // 市名称
				   shop_district_name:'', // 区名称
	            },
				stampIngList:[],
	            addressValue: "",
	            flag: false, //防重复标识
	            defaultRegions: [],
				uploadActionUrl: Config.baseUrl + '/api/upload/chatimg', //表情图标
	            rules: {
					operator_name:[{required: true, message: "请输入姓名", trigger: "blur"}],
	                organization_name: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
	                operator_mobile: [{ required: true, validator: isoperator_mobile, trigger: "blur" }],
					img_list:[{ required: true, trigger: "blur" }],
	                full_address: [{ required: true, validator: fullAddress, trigger: "blur" }]
	            },
	            province: [],
	            city: [],
	            district: [],
				logo_img:[],
	            pickerValueArray: [],
	            multiIndex: [0, 0, 0],
	            isInitMultiArray: false,
	            // 是否加载完默认地区
	            isLoadDefaultAreas: false,
	            loading: true,
				yes: true,
				back:'',
				showBtnStatus:0,
				showrenStatus:false
	        }
	    },
	    created() {
			this.getMemberOrganizationInfo()
			this.back = this.$route.params.back
	        this.getAddressDetail()
	        this.getDefaultAreas(0, {
	            level: 0
	        })
			this.getSealList()
	    },
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false
			}, 300)
		},
		
	    watch: {
	        defaultRegions: {
	            handler(arr, oldArr = []) {
	                // 避免传的是字面量的时候重复触发
	                if (arr.length !== 3 || arr.join("") === oldArr.join("")) return
	                this.handleDefaultRegions()
					console.log('8')
	            },
	            immediate: true
	        }
	    },
	    methods: {
			goauth(){
				this.$router.push({ path: "/member/qizongauthentication" })
			},
			addStamping(){
				addStamping({
				})
				    .then(res => {
						window.open(res.data)
						console.log(res)
				    })
				    .catch(err => {
						console.log(err)
						this.$message({
							message:err.message,
							type:'warning'
						})
					})
			},
			getSealList(){
				getSealList({
				})
				.then(res => {
					this.stampIngList = res.data
				})
				.catch(err => {})
			},
			getMemberOrganizationInfo(){
				getMemberOrganizationInfo({
				})
				    .then(res => {
						if(res.data == null){
							this.showrenStatus = true
						}
						if(res.code >= 0 && res.data){
							this.formData.organization_name = res.data.organization_name
							this.formData.operator_name = res.data.operator_name
							this.formData.operator_mobile = res.data.operator_mobile
							this.formData.province_id = res.data.province_name
							this.formData.city_id = res.data.city_name
							this.formData.district_id = res.data.district_name
							this.formData.province_name = res.data.province_name
							this.formData.city_name = res.data.city_name
							this.formData.district_name = res.data.district_name
							this.business_license = res.data.business_license.split(',')
							this.formData.full_address = res.data.province_name + '-' + res.data.city_name + '-' + res.data.district_name
							this.defaultRegions = [res.data.province_id, res.data.city_id, res.data.district_id]
							this.showBtnStatus = res.data.state
						}
				    })
				    .catch(err => {})
			},
			submitBtn(){
				if(this.flag) return;
				this.flag = true
				if(!this.formData.organization_name){
					this.$message({
						message:'请输入公司名称',
						type:'warning'
					})
					this.flag = false
					return false;
				}
				if (this.formData.full_address == '') {
					this.$message({
						message:'请选择省市区县',
						type:'warning'
					})
					this.flag = false;
					return false;
				}
				if(!this.business_license.length){
					this.$message({
						message:'请上传营业执照照片',
						type:'warning'
					})
					this.flag = false
					return false;
				}
				
				if (this.formData.operator_name == '') {
					this.$message({
						message:'请输入法人姓名',
						type:'warning'
					})
					this.flag = false;
					return false;
				}
				if (this.formData.operator_mobile == '' ) {
					this.$message({
						message:'请输入手机号',
						type:'warning'
					})
					this.flag = false;
					return false;
				}
				var p1 = /^[1][3,4,5,7,8][0-9]{9}$/;;
				if(!p1.test(this.formData.operator_mobile)){
					this.$message({
						message:'请输入正确的手机号',
						type:'warning'
					})
					this.flag = false;
					return false
				}
				this.formData.business_license = this.business_license.toString()
				console.log(this.formData)
				let addressValueArr = this.addressValue.split('-'),
					shop_province_address = this.formData.full_address.split('-');
					this.formData.province_name = shop_province_address[0]
					this.formData.city_name = shop_province_address[1]
					this.formData.district_name = shop_province_address[2]
				refreshOrganization(this.formData)
				    .then(res => {
						if(res.code>=0){
							this.$message({
								message:'提交成功，请耐心等待审核',
								type:'success'
							})
							window.open(res.data);
						}
				    })
				    .catch(err => {})
			},
			deleteBusinessLicense(index){
				this.business_license.splice(index,1)
			},
			handleAvatarSuccess(res, file) {
				this.business_license.push(file.response.data.pic_path)
			},
	        /**
	         * 改变省
	         */
	        changeProvice(id) {
	            this.getAreas(id, data => (this.city = data))
	            let obj = {}
	            obj = this.province.find(item => {
	                //这里的province就是上面遍历的数据源
	                return item.id === id //筛选出匹配数据
	            })
	            this.formData.city_id = ""
	            this.formData.district_id = ""
	            this.formData.full_address = obj.name // 设置选中的地址
	        },
	        /**
	         * 改变市
	         */
	        changeCity(id) {
	            this.getAreas(id, data => (this.district = data))
	            let obj = {}
	            obj = this.city.find(item => {
	                //这里的province就是上面遍历的数据源
	                return item.id === id //筛选出匹配数据
	            })
	            this.formData.district_id = ""
	            this.formData.full_address = this.formData.full_address + "-" + obj.name
	        },
	        /**
	         * 改变区
	         */
	        changeDistrict(id) {
	            let obj = {}
	            obj = this.district.find(item => {
	                //这里的province就是上面遍历的数据源
	                return item.id === id //筛选出匹配数据
	            })
	            this.formData.full_address = this.formData.full_address + "-" + obj.name
	        },
	        /**
	         * 获取地址信息
	         */
	        getAddressDetail() {
	            addressInfo({
	                id: this.formData.id
	            })
	                .then(res => {
	                    let data = res.data
	                    if (data != null) {
	                        this.formData.operator_name = data.name
	                        this.formData.operator_mobile = data.operator_mobile
	                        this.formData.telephone = data.telephone
	                        this.formData.address = data.address
	                        this.formData.full_address = data.full_address
	                        this.formData.latitude = data.latitude
	                        this.formData.longitude = data.longitude
	                        this.formData.is_default = data.is_default
	                        this.formData.province_id = data.province_id
	                        this.formData.city_id = data.city_id
	                        this.formData.district_id = data.district_id
	                        this.defaultRegions = [data.province_id, data.city_id, data.district_id]
	                    }
	                })
	                .catch(err => {})
	        },
	        // 异步获取地区
	        getAreas(pid, callback) {
	            getArea({
	                pid: pid
	            })
	                .then(res => {
	                    if (res.code == 0) {
	                        var data = []
	                        res.data.forEach((item, index) => {
	                            data.push(item)
	                        })
	                        if (callback) callback(data)
	                    }
	                })
	                .catch(err => {})
	        },
	
	        /**
	         * 获取省市区列表
	         */
	        getDefaultAreas(pid, obj) {
	            getArea({
	                pid: pid
	            })
	                .then(res => {
	                    if (res.code == 0) {
	                        var data = []
	                        var selected = undefined
	                        res.data.forEach((item, index) => {
	                            if (obj != undefined) {
	                                if (obj.level == 0 && obj.province_id != undefined) {
	                                    selected = obj.province_id
	                                } else if (obj.level == 1 && obj.city_id != undefined) {
	                                    selected = obj.city_id
	                                } else if (obj.level == 2 && obj.district_id != undefined) {
	                                    selected = obj.district_id
	                                }
	                            }
	
	                            if (selected == undefined && index == 0) {
	                                selected = item.id
	                            }
	                            data.push(item)
	                        })
	
	                        this.pickerValueArray[obj.level] = data
	                        if (obj.level + 1 < 3) {
	                            obj.level++
	                            this.getDefaultAreas(selected, obj)
	                        } else {
	                            this.isInitMultiArray = true
	                            this.isLoadDefaultAreas = true
	                        }
	
	                        this.province = this.pickerValueArray[0]
	                    }
	                    this.loading = false
	                })
	                .catch(err => {
	                    this.loading = false
	                })
	        },
	
	        /**
	         * 渲染默认值
	         */
	        handleDefaultRegions() {
	            var time = setInterval(() => {
	                if (!this.isLoadDefaultAreas) return
	                this.isInitMultiArray = false
	                for (let i = 0; i < this.defaultRegions.length; i++) {
	                    for (let j = 0; j < this.pickerValueArray[i].length; j++) {
	                        this.province = this.pickerValueArray[0]
	
	                        // 匹配省
	                        if (this.defaultRegions[i] == this.pickerValueArray[i][j].id) {
	                            // 设置选中省
	                            this.$set(this.multiIndex, i, j)
	                            // 查询市
	                            this.getAreas(this.pickerValueArray[i][j].id, data => {
	                                this.city = data
	
	                                for (let k = 0; k < this.city.length; k++) {
	                                    if (this.defaultRegions[1] == this.city[k].id) {
	                                        // 设置选中市
	                                        this.$set(this.multiIndex, 1, k)
	
	                                        // 查询区县
	                                        this.getAreas(this.city[k].id, data => {
	                                            this.district = data
	
	                                            // 设置选中区县
	                                            for (let u = 0; u < this.district.length; u++) {
	                                                if (this.defaultRegions[2] == this.district[u].id) {
	                                                    this.$set(this.multiIndex, 2, u)
	                                                    this.handleValueChange({
	                                                        detail: {
	                                                            value: [j, k, u]
	                                                        }
	                                                    })
	                                                    break
	                                                }
	                                            }
	                                        })
	
	                                        break
	                                    }
	                                }
	                            })
	                        }
	                    }
	                }
	                if (this.isLoadDefaultAreas) clearInterval(time)
	            }, 100)
	        },
	        handleValueChange(e) {
	            // 结构赋值
	            let [index0, index1, index2] = e.detail.value
	            let [arr0, arr1, arr2] = this.pickedArr
	            let address = [arr0[index0], arr1[index1], arr2[index2]]
	
	            this.formData.full_address = ""
	            for (let i = 0; i < address.length; i++) {
	                if (this.formData.full_address) {
	                    this.formData.full_address = this.formData.full_address + "-" + address[i].name
	                } else {
	                    this.formData.full_address = this.formData.full_address + address[i].name
	                }
	            }
	        },
	
	        /**
	         * 保存地址
	         */
	        saveAddress(formName) {
	            this.$refs[formName].validate(valid => {
	                if (valid) {
	                    var data = {
	                        name: this.formData.operator_name,
	                        operator_mobile: this.formData.operator_mobile,
	                        telephone: this.formData.telephone,
	                        province_id: this.formData.province_id,
	                        city_id: this.formData.city_id,
	                        district_id: this.formData.district_id,
	                        community_id: "",
	                        address: this.formData.address,
	                        full_address: this.formData.full_address,
	                        latitude: this.formData.latitude,
	                        longitude: this.formData.longitude,
	                        is_default: this.formData.is_default
	                    }
	
	                    data.url = "add"
	                    if (this.formData.id) {
	                        data.url = "edit"
	                        data.id = this.formData.id
	                    }
	                    if (this.flag) return
	                    this.flag = true
	
	                    saveAddress(data)
	                        .then(res => {
	                            if (res.code == 0) {
	                                this.$router.push({ path: "/member/delivery_address" })
	                            } else {
	                                this.flag = false
	                                this.$message({ message: res.message, type: "warning" })
	                            }
	                        })
	                        .catch(err => {
	                            this.flag = false
	                            this.$message.error(err.message)
	                        })
	                } else {
	                    return false
	                }
	            })
	        }
	    }
	}
</script>

<style lang="scss">
	.list{
		display: flex;
		flex-wrap: wrap;
	}
	.image-list{
		margin-right: 25px;
		position: relative;
		width: 273px;
		height: 162px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #e8ecec;
		border-radius: 5px;
		margin-bottom: 20px;
		.image-item{
			width: 80px;
			height: 80px;
			img{
				width:100%;
				height: 100%;
				object-fit: contain;
			}
		}
		.image-status{
			left: 0px;
			color: #08a89c;
			font-size: 12px;
			background: #d8f6f4;
			border-radius: 0 100px 100px 0;
			font-size: 12px;
			line-height: 20px;
			padding: 0 8px;
			position: absolute;
			top: 8px;
		}
		.status-no{
			left: 0px;
			color: #878787;
			font-size: 12px;
			background: #dadada;
			border-radius: 0 100px 100px 0;
			font-size: 12px;
			line-height: 20px;
			padding: 0 8px;
			position: absolute;
			top: 8px;
		}
		.image-name{
			border-top: 1px solid #e8ecec;
			background-color: #fcfcfc;
			line-height: 32px;
			position: absolute;
			font-size: 14px;
			color: #000;;
			width: 100%;
			bottom: 0;
			left:0;
			text-align: center;
		}
	}
	.add-btn{
		padding:20px 0 ;
		button{
			line-height: 1;
		}
		
	}
	.submit-btn{
		margin-left: 140px;
	}
	.form-box{
		.el-input.is-disabled{
			.el-input__inner{
				color: #333;
				background-color: transparent;
			}
		}
	}
	.opload-box{
		margin-right: 10px;
		border: 1px solid #DCDFE6;
		border-radius: 8px;
		width: 150px;
		height: 100px;
		display: flex;
		margin-bottom: 10px;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		.delete-icon{
			position: absolute;
			top: -20px;
			right: -8px;
			cursor: pointer;
		}
		.upload-tit{
			line-height: 2;
			color: #828387;
			font-size: 12px;
		}
	}
	.fotm-content{
		padding: 50px 0;
		background-color: #fff;
	}
	.authentiaciton{
		padding: 0 20px;
		.top-jindu{
			.jindu-box{
				margin-top: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100px;
				background-color: #F3F3F6;
				.content{
					display:flex;
					align-items: center;
					.circle{
						margin-right: 12px;
						width: 24px;
						height: 24px;
						background-color: #CAEBE6;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						.center{
							width: 12px;
							height: 12px;
							border-radius: 50%;
							background-color: #10C59F;
						}
					}
					.circle-no{
						margin-right: 12px;
						width: 24px;
						height: 24px;
						background-color: #778AA3;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						.center-no{
							color: #fff;
							font-weight: bold;
						}
					}
					.circle-dui{
						margin-right: 12px;
						width: 24px;
						height: 24px;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.line{
						margin: 0 30px;
						width: 140px;
						height: 2px;
						background-color: #CAEBE6;
					}
				}
			}
		}
		.form-box{
			display: flex;
			align-items: center;
			background-color: #fff;
			justify-content: center;
			.content{
				width: 676px;
				.top-title{
					font-size: 17px;
					border-bottom: 1px solid #F4F4F4;
					cursor: pointer;
					color:#989898;
					.bottom-image{
						display:flex;
						align-items: center;
						justify-content: center;
						img{
							display: block;
						}
					}
				}
			}
		}
	}
	.box {
		width: 100%;
		position: relative;
	}
	
	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}
	
	.el-card.is-always-shadow,
	.el-card.is-hover-shadow:focus,
	.el-card.is-hover-shadow:hover {
	    box-shadow: unset;
	}
	
	.el-card {
	    border: 0;
	}
	
	.ns-len-input {
		width: 400px;
		height: 40px;
	}
	.ns-len-input input{
		height: 30px;
		border-radius: 1px;
	}
	.el-select {
	    margin-right: 10px;
		width:calc(100% /3 - 34px);
	}
	.el-select input{
		height: 30px;
		border-radius: 1px;
	}
	.el-form-item__label{
		font-size: 14px;
		width:140px !important
	}
	textarea{
		resize: none;
		outline: none;
		border: none;
		border: 1px solid #DCDFE6;
		height: 80px;
	}
	.ns-len-input-textarea{
		height: 80px;
		padding: 10px;
		box-sizing: border-box;
	}
	.business-license{
		display: flex;
		flex-wrap: wrap;
	}
	.yi-img{
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
	.el-form-item{
		margin-bottom: 20px;
	}
	.submin-btn{
		margin-left: 140px;
		background-color: #00A98F;
		color: #fff;
		width: 150px;
	}
	.img-box{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 300px;
		padding-bottom: 200px;
		img{
			margin-bottom: 15px;
		}
	}
	.flex-tab{
		display: flex;
		.top-title{
			margin-right:40px;
		}
	}
	.taciveTab{
		color:black !important;
		font-size: 18px !important;
	}
	.empty-title{
		text-align: center;
		color: #989898;
		cursor:pointer;
	}
	.click-btn{
		text-decoration:underline;
		color: #00A98F;
	}
</style>